import { PopupButton } from "@typeform/embed-react";
import React from "react";
import { Link } from "react-router-dom";
import logo from "./../images/logo/logo.png";

function Footer() {
  return (
    <div>
      {/* ...::: Start Footer Section :::... */}
      <footer className="footer-section section-bg overflow-hidden pos-relative">
        <div className="footer-inner-shape-top-left" />
        <div className="footer-inner-shape-top-right" />
        <div className="footer-section-top section-gap-t-165">
          <div className="container">
            <div className="row">
              <div className="col-12">
                {/* Start Section Content */}
                <div className="section-content pos-relative text-center">
                  <span className="section-tag">Get Latest Updates</span>
                  <h2 className="section-title">Subscribe For Newsletter</h2>
                </div>
                {/* End Section Content */}
              </div>
            </div>
            <div className="footer-top-wrapper text-center">
              <div className="row">
                <div className="col-12">
                  {/* return it when I have time to integrate email and Salesforce 
                  <form action="#" className="footer-newsletter">
                    <input type="email" placeholder="name@domain.com" />
                    <button className="submit-btn" type="submit">
                      Subscribe Now
                    </button>
                  </form> */}
                  <PopupButton
                    id="dbHwb4zZ"
                    style={{ fontSize: 20 }}
                    className="subscribe-btn"
                  >
                    Subscribe Now
                  </PopupButton>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-center section-gap-tb-165">
          <div className="container">
            <div className="row justify-content-between align-items-center mb-n5">
              <div className="col-auto mb-5">
                {/* Start Single Footer Info */}
                <div className="footer-single-info">
                  <a href="tel:+17077169325" className="info-box">
                    <span className="icon">
                      <i className="icofont-phone" />
                    </span>
                    <span className="text">7077169325</span>
                  </a>
                </div>
                {/* Start Single Footer Info */}
              </div>
              <div className="col-auto mb-5">
                {/* Start Single Footer Info */}
                <div className="footer-single-info">
                  <a href="mailto:amendieta704@gmail.com" className="info-box">
                    <span className="icon">
                      <i className="icofont-envelope-open" />
                    </span>
                    <span className="text">amendieta704@gmail.com</span>
                  </a>
                </div>
                {/* Start Single Footer Info */}
              </div>
              <div className="col-auto mb-5">
                {/* Start Single Footer Info */}
                <div className="footer-single-info">
                  <ul className="social-link">
                    <li>
                      <a
                        href="https://www.facebook.com/tony.mendieta1"
                        target="_blank"
                      >
                        <i className="icofont-facebook" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/antoniomendieta_ceo"
                        target="_blank"
                      >
                        <i className="icofont-instagram" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/in/antoniomendieta/"
                        target="_blank"
                      >
                        <i className="icofont-linkedin" />
                      </a>
                    </li>
                  </ul>
                </div>
                {/* Start Single Footer Info */}
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row justify-content-center justify-content-md-between align-items-center flex-column-reverse flex-md-row">
              <div className="col-auto">
                <div className="footer-copyright">
                  <p className="copyright-text">
                    © 2022 Antonio Mendieta aka MR. AM
                  </p>
                </div>
              </div>
              <div className="col-auto">
                <Link to="/" className="footer-logo">
                  <div className="logo">
                    <img src={logo} alt="" />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* ...::: End Footer Section :::... */}
    </div>
  );
}

export default Footer;
