import React from "react";
import { Link } from "react-router-dom";
import { PopupButton } from "@typeform/embed-react";

//docs
import resumedocx from "../docs/am_resume.docx";
import resumepdf from "../docs/am_resume.pdf"
import Breadcrumbs from "../components/Breadcrumbs";
import {BIO, BIO_SWITCHER} from "../constants/constants";
import Switcher from "../components/Switcher";
import Tabs from "../components/Tabs";
import CompanyLogos from "../components/CompanyLogos";
import TestimonialDisplay from "../components/TestimonialDisplay";
import CounterDisplay from "../components/CounterDisplay";

class Bio extends React.Component {
 
render(){
  return (
    <div>
      <main className="main-wrapper">
        
        {/* ...::: Start Breadcrumb Section :::... */}
        <div className="breadcrumb-section section-bg overflow-hidden pos-relative">
          <div className="breadcrumb-shape-top-left" />
          <div className="breadcrumb-shape-bottom-right" />
          <div className="breadcrumb-box">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="breadcrumb-content">
                    <h2 className="title">Bio</h2>
                    <ul className="breadcrumb-link">
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li className="active" aria-current="page">
                        Bio
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ...::: End Breadcrumb Section :::... */}
        {/* ...::: Start Vider Info Display Section :::... */}
        <div className="video-info-display-section section-gap-tb-165">
          <div className="video-info-display-box">
            <div className="container">
              <div className="video-info-display-wrapper">
                <div className="row align-items-center">
                  <div className="col-xl-5 col-lg-6 col-md-6 col-sm-8 col-10 offset-1 offset-sm-2 offset-md-3 offset-lg-0">
                    <div className="video-card">
                      <a
                        className="wave-btn"
                        href="https://youtu.be/MKjhBO2xQzg"
                        data-autoplay="true"
                        data-vbtype="video"
                      >
                        <div className="ripple">
                          <i className="icofont-ui-play" />
                        </div>
                      </a>
                      {/* <a
                        href={resumepdf}
                        className="btn btn-xl btn-outline-one icon-space-left"
                      >
                        Get Resume <i className="icofont-download" />
                      </a> */}
                      <PopupButton id="GE1XlP0I" style={{fontSize: 20}} className="btn btn-xl btn-outline-one icon-space-left">
                    Book Me
                  </PopupButton>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-8 offset-md-2 offset-lg-0 offset-xl-1">
                    <div className="video-info-content">
                      <h2 className="title">I’m Antonio Mendieta</h2>
                      <h3 className="sub-title">
                        I have lead and managed multiple tech solutions from inception to release. So I am a bit of an expert.
                      </h3>
                      <p>
                        With a BS in Game Design and Simulation, I decided to 
                        start an organization at the age of 22. Since then, I have learned many leadership and communication
                        skills to maintain the organization and publish multiple applications within its name. 
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ...::: End Vider Info Display Section :::... */}

        {/* ...::: Start Resume Info Display Section :::... */}
        <Tabs/>
        {/* ...::: End Resume Info Display Section :::... */}
        
        {/* ...::: Start Counter Display Section :::... */}
        {/* <CounterDisplay/> */}
        {/* ...::: End Counter Display Section :::... */}
        
        {/* ...::: Start Testimonial Display Section :::... */}
        {/* <TestimonialDisplay/> */}
        {/* ...::: End Testimonial Display Section :::... */}
        
        {/* ...::: Start Company Logo Display Section :::... */}
        <CompanyLogos/>
        {/* ...::: End Company Logo Display Section :::... */}
        
        {/* material-scrolltop button */}
        <button className="material-scrolltop" type="button" />
      </main>
    </div>
  );
}
}

export default Bio;
