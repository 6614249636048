import React from "react";
import { SERVICE_CARDS } from "../constants/constants";

function ServiceGrid(props) {
  return (
    <div className="service-list-section section-gap-tb-165">
      <div className="container">
        <div className="row mb-n6">
          {SERVICE_CARDS.map((content, index) => {
            return (
              <div className="col-xl-4 col-md-6 col-12 mb-6">
                {/* Start Service Single Item */}
                <div className="service-box-single-item">
                  <div className="inner-shape inner-shape-top-right" />
                  <div className="icon">
                    <img src="assets/images/icon/service-icon-1.png" alt="" />
                  </div>
                  <h4 className="title">
                    <a href={content.href}>{content.title}</a>
                  </h4>
                  <ul className="list-item">
                    {content.list.map((listitem) => {
                      return <li>{listitem}</li>;
                    })}
                  </ul>
                  <div className="inner-shape inner-shape-bottom-right" />
                </div>
                {/* End Service Single Item */}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default ServiceGrid;
