import React from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Routes,
  BrowserRouter,
  Outlet,
} from "react-router-dom";
import Home from "./pages/Home.js";
import Blog from "./pages/Blog.js";
import Navbar from "./components/Navbar.js";
import Services from "./pages/Services.js";
import ProjectList from "./pages/SolutionList.js";
import Footer from "./components/Footer.js";
import Gallery from "./pages/Gallery.js";
import Bio from "./pages/Bio.js";

const Layout =(
  <div className="App">
    <Navbar />
    <Outlet />
    <Footer />
  </div>
);



export default function App() {
  return (
    <React.Fragment>
      <Routes>
        <Route path="/" element={Layout}>
          <Route index element={<Home />} />
          <Route path="/bio" element={<Bio/>} />


          <Route path="/gallery" element={<Gallery></Gallery>}/>
          <Route path="/blog" element={<Blog />} />

          <Route path="/services" element={<Services/>} />
          <Route path="/services/speaking" element={<Services/>} />
          <Route path="/services/consulting" element={<Services/>} />
          <Route path="/solutions" element={<ProjectList/>} />
        </Route>
      </Routes>
    </React.Fragment>
  );
}
