import React from "react";
import { Link } from "react-router-dom";

// import "../styles/components/breadcrumbs.scss"

class Breadcrumbs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
    };
  }

  render() {
    return (
      <div className="resume-info-display-section  section-gap-tb-165 section-bg">
        <div className="resume-info-display-box">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <ul className="resume-tab nav">
                  {this.state.data.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        {item.link ? (
                          <Link className="nav-item" to={item.link}>
                            <span>{item.name}</span>
                          </Link>
                        ) : (
                          <span className="nav-link active">{item.name}</span>
                        )}
                      </React.Fragment>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Breadcrumbs;
