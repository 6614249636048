import React, { Component } from "react";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";

//css files
import "./../style/css/style.css";
import "./../style/css/style.min.css";

import "./../style/css/plugins/animate.css";
import "./../style/css/plugins/plugins.min.css";
import "./../style/css/plugins/swiper-bundle.min.css";
import "./../style/css/plugins/venobox.min.css";

import "./../style/css/vendor/bootstrap.min.css";
import "./../style/css/vendor/icofont.min.css";
import "./../style/css/vendor/vendor.min.css";

//scss files
import "./../style/scss/style.scss";

import "./../style/scss/base/common/_button.scss";
import "./../style/scss/base/common/_form.scss";
import "./../style/scss/base/common/_measuremant.scss";
import "./../style/scss/base/common/_section.scss";
import "./../style/scss/base/common/_typhography.scss";
import "./../style/scss/base/_common.scss";
import "./../style/scss/base/_material-scroll-top.scss";
import "./../style/scss/base/_mixins.scss";
import "./../style/scss/base/_variables.scss";

//Typeform
import {PopupButton} from "@typeform/embed-react"

class Navbar extends Component {
  render() {
    return (
      <div>
        <Helmet></Helmet>
        {/* .....:::::: Start Header Section :::::.... */}
        <header className="header-section sticky-header d-none d-lg-block">
          <div className="header-wrapper">
            <div className="container">
              <div className="row justify-content-between align-items-center">
                <div className="col">
                  {/* Start Header Logo */}
                  <Link to="/" className="header-logo">
                    <img src="assets/images/logo/logo.png" alt="" />
                  </Link>
                  {/* End Header Logo */}
                </div>
                <div className="col-auto">
                  {/* Start Header Menu */}
                  <ul className="header-nav">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/bio">Bio</Link>
                    </li>

                    <li>
                      <Link to="/services">Services</Link>
                    </li>

                    {/* <li className="has-dropdown">
                      <Link to="/services">Services</Link>
                      <ul className="submenu">
                        <li>
                          <Link to="/services/speaking">Speaking</Link>
                        </li>
                        <li>
                          <Link to="/services/consulting">Consulting</Link>
                        </li>
                      </ul>
                    </li> */}

                    {/* ADDD LATER
                    <li>
                      <Link to="/blog">Blog</Link>
                    </li> */}

                    {/* Save it for when I feel like categarizing my blog */}
                    {/* <li className="has-dropdown">
                      <a href="blog-list.html">Blog</a>
                      <ul className="submenu">
                        <li>
                          <a href="blog-list.html">Blog List Full Width</a>
                        </li>
                        <li>
                          <a href="blog-list-sidebar-left.html">
                            Blog List Left Sidebar
                          </a>
                        </li>
                      </ul>
                    </li> */}
                    <li>
                      <Link to="/gallery">Gallery</Link>
                    </li>
                    <li>
                      <Link to="/solutions">Solutions</Link>
                    </li>
                    {/* <li className="has-dropdown">
                      <a href="#">Pages</a>
                      <ul className="submenu">
                        <li>
                          <a href="about.html">About Us</a>
                        </li>
                        <li>
                          <a href="solution-list.html">Solution</a>
                        </li>
                        <li>
                          <a href="solution-details.html">Solution Details</a>
                        </li>
                        <li>
                          <a href="faq.html">FAQ</a>
                        </li>
                        <li>
                          <a href="404-page.html">404 Page</a>
                        </li>
                      </ul>
                    </li> */}
                    <li>
                      <a href="mailto:amendieta704@gmail.com">Contact</a>
                      {/* <Link to="/contact">Contact</Link> */}
                    </li>
                  </ul>
                  {/* End Header Menu */}
                </div>
                <div className="col">
                  <div className="header-btn-link text-end">
                    {/* <a href="mailto:amendieta704@gmail.com" className="btn btn-sm btn-outline-one icon-space-left">
                      Book Me <i className="icofont-double-right" />
                    </a> */}
                    <PopupButton
                      id="GE1XlP0I"
                      style={{ fontSize: 20 }}
                      className="btn btn-xl btn-outline-one icon-space-left"
                    >
                      Book Me
                    </PopupButton>

                    {/* <Link
                      to="/contact"
                      className="btn btn-sm btn-outline-one icon-space-left"
                    >
                      Book Me <i className="icofont-double-right" />
                    </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        {/* .....:::::: End Header Section :::::.... */}
        {/* .....:::::: Start Mobile Header Section :::::.... */}
        <div className="mobile-header d-block d-lg-none">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col">
                <div className="mobile-logo">
                  <Link to="/">
                    <img src="assets/images/logo/logo.png" alt="" />
                  </Link>
                </div>
              </div>
              <div className="col">
                <div className="mobile-action-link text-end">
                  <a
                    // href="#mobile-menu-offcanvas"
                    className="offcanvas-toggle offside-menu"
                  >
                    <i className="icofont-navigation-menu" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* .....:::::: Start MobileHeader Section :::::.... */}
        {/*  Start Offcanvas Mobile Menu Section */}
        <div
          id="mobile-menu-offcanvas"
          className="offcanvas offcanvas-rightside offcanvas-mobile-menu-section"
        >
          {/* Start Offcanvas Header */}
          <div className="offcanvas-header text-end">
            <button className="offcanvas-close">
              <i className="icofont-close-line" />
            </button>
          </div>{" "}
          {/* End Offcanvas Header */}
          {/* Start Offcanvas Mobile Menu Wrapper */}
          <div className="offcanvas-mobile-menu-wrapper">
            {/* Start Mobile Menu  */}
            <div className="mobile-menu-bottom">
              {/* Start Mobile Menu Nav */}
              <div className="offcanvas-menu">
                <ul>
                  <li>
                    <Link to="/">
                      <span>Bio</span>
                    </Link>
                  </li>
                  <li>
                    <a href="#">
                      <span>Services</span>
                    </a>
                    <ul className="mobile-sub-menu">
                      <li>
                        <a href="service-list.html">Service List</a>
                      </li>
                      <li>
                        <a href="service-details.html">Service Details</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#">
                      <span>Blog</span>
                    </a>
                    <ul className="mobile-sub-menu">
                      <li>
                        <a href="blog-list.html">Blog List Full Width</a>
                      </li>
                      <li>
                        <a href="blog-list-sidebar-left.html">
                          Blog List Left Sidebar
                        </a>
                      </li>
                      <li>
                        <a href="blog-list-sidebar-right.html">
                          Blog List Right Sidebar
                        </a>
                      </li>
                      <li>
                        <a href="blog-details.html">Blog Details Full Width</a>
                      </li>
                      <li>
                        <a href="blog-details-sidebar-left.html">
                          Blog Details Left Sidebar
                        </a>
                      </li>
                      <li>
                        <a href="blog-details-sidebar-right.html">
                          Blog Details Right Sidebar
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#">
                      <span>Pages</span>
                    </a>
                    <ul className="mobile-sub-menu">
                      <li>
                        <a href="about.html">About Us</a>
                      </li>
                      <li>
                        <a href="solution-list.html">Solution</a>
                      </li>
                      <li>
                        <a href="solution-details.html">Solution Details</a>
                      </li>
                      <li>
                        <a href="faq.html">FAQ</a>
                      </li>
                      <li>
                        <a href="404-page.html">404 Page</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="contact.html">
                      <span>Contact</span>
                    </a>
                  </li>
                </ul>
              </div>{" "}
              {/* End Mobile Menu Nav */}
            </div>{" "}
            {/* End Mobile Menu */}
            {/* Start Mobile contact Info */}
            <div className="mobile-contact-info text-center">
              <ul className="social-link">
                <li>
                  <a target="_blank" href="https://example.com">
                    <i className="icofont-facebook" />
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://example.com">
                    <i className="icofont-twitter" />
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://example.com">
                    <i className="icofont-skype" />
                  </a>
                </li>
              </ul>
            </div>
            {/* End Mobile contact Info */}
          </div>{" "}
          {/* End Offcanvas Mobile Menu Wrapper */}
        </div>
        {/* ...:::: End Offcanvas Mobile Menu Section:::... */}
        {/* Offcanvas Overlay */}
        <div className="offcanvas-overlay" />
      </div>
    );
  }
}

export default Navbar;
