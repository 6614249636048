import React from "react";

//docs
import resumedocx from "../docs/am_resume.docx";
import resumepdf from "../docs/am_resume.pdf"

export default function Blog() {
  return (
    <div>
      <main className="main-wrapper">
        {/* ...::: Start Breadcrumb Section :::... */}
        <div className="breadcrumb-section section-bg overflow-hidden pos-relative">
          <div className="breadcrumb-shape-top-left" />
          <div className="breadcrumb-shape-bottom-right" />
          <div className="breadcrumb-box">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="breadcrumb-content">
                    <h2 className="title">Blog List Left Sidebar</h2>
                    <ul className="breadcrumb-link">
                      <li>
                        <a href="blog-list.html">Blog</a>
                      </li>
                      <li className="active" aria-current="page">
                        {" "}
                        Blog List Left Sidebar
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ...::: End Breadcrumb Section :::... */}
        {/* ...::: Start Blog List Section :::... */}
        <div className="blog-list-section section-mt-165">
          <div className="blog-list-box">
            <div className="container">
              <div className="row flex-lg-row flex-column-reverse">
                <div className="col-xl-4 col-lg-5">
                  {/* Start Sidebar Widget Area*/}
                  <div className="sidebar-widget-area">
                    {/* Start Sidebar Widget Single Item - Search Widgets */}
                    <div className="sidebar-widget-single-area search-widgets">
                      <form
                        className="search-widgets-box"
                        action="#"
                        method="post"
                      >
                        <input type="search" placeholder="Search here" />
                        <button>
                          <i className="icofont-search-1" />
                        </button>
                      </form>
                    </div>
                    {/* End Sidebar Widget Single Item - Search Widgets */}
                    {/* Start Sidebar Widget Single Item - Catagory Widgets */}
                    <div className="sidebar-widget-single-area catagory-widgets">
                      <h3 className="title">Category</h3>
                      <ul className="widget-nav-list catagory-item-list">
                        <li>
                          <a href="#">
                            <span className="text">UI/UX Design</span>
                            <span className="icon">
                              <i className="icofont-double-right" />
                            </span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span className="text">Web Development</span>
                            <span className="icon">
                              <i className="icofont-double-right" />
                            </span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span className="text">Brand Identity</span>
                            <span className="icon">
                              <i className="icofont-double-right" />
                            </span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span className="text">Digital Marketing</span>
                            <span className="icon">
                              <i className="icofont-double-right" />
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    {/* End Sidebar Widget Single Item - Catagory Widgets */}
                    {/* Start Sidebar Widget Single Item - Resume Widgets */}
                    <div className="sidebar-widget-single-area resume-widgets">
                      <h3 className="title">Resume</h3>
                      <ul className="widget-nav-list resume-item-list">
                        <li>
                          <a href={resumepdf}>
                            <span className="text">Get Resume.pdf</span>
                            <span className="icon">
                              <i className="icofont-file-pdf" />
                            </span>
                          </a>
                        </li>
                        <li>
                          <a href={resumedocx}>
                            <span className="text">Get Resume.doc</span>
                            <span className="icon">
                              <i className="icofont-file-word" />
                            </span>
                          </a>
                        </li>
                        {/* <li>
                          <a href="#">
                            <span className="text">Get Resume.ppt</span>
                            <span className="icon">
                              <i className="icofont-file-powerpoint" />
                            </span>
                          </a>
                        </li> */}
                      </ul>
                    </div>
                    {/* End Sidebar Widget Single Item - Resume Widgets */}
                    {/* Start Sidebar Widget Single Item - Recent Post Widgets */}
                    <div className="sidebar-widget-single-area recentpost-widget">
                      <h3 className="title">Recent Post</h3>
                      <ul className="recent-blog-item-list">
                        <li>
                          <a
                            href="blog-details-sidebar-left.html"
                            className="image"
                          >
                            <img
                              src="assets/images/blog/recent-blog-img-1.jpg"
                              alt=""
                            />
                          </a>
                          <div className="content">
                            <h4 className="title">
                              <a href="blog-details-sidebar-left.html">
                                Mistakes You Can Easily Avoid.{" "}
                              </a>
                            </h4>
                            <div className="post-meta">
                              <a href="#" className="date icon-space-right">
                                <i className="icofont-calendar" /> 09 Apr, 2021
                              </a>
                            </div>
                          </div>
                        </li>
                        <li>
                          <a
                            href="blog-details-sidebar-left.html"
                            className="image"
                          >
                            <img
                              src="assets/images/blog/recent-blog-img-2.jpg"
                              alt=""
                            />
                          </a>
                          <div className="content">
                            <h4 className="title">
                              <a href="blog-details-sidebar-left.html">
                                We take your amazing journey.
                              </a>{" "}
                            </h4>
                            <div className="post-meta">
                              <a href="#" className="date icon-space-right">
                                <i className="icofont-calendar" /> 10 Apr, 2021
                              </a>
                            </div>
                          </div>
                        </li>
                        <li>
                          <a
                            href="blog-details-sidebar-left.html"
                            className="image"
                          >
                            <img
                              src="assets/images/blog/recent-blog-img-3.jpg"
                              alt=""
                            />
                          </a>
                          <div className="content">
                            <h4 className="title">
                              <a href="blog-details-sidebar-left.html">
                                Things About Portfolio.
                              </a>{" "}
                            </h4>
                            <div className="post-meta">
                              <a href="#" className="date icon-space-right">
                                <i className="icofont-calendar" /> 13 Apr, 2021
                              </a>
                            </div>
                          </div>
                        </li>
                        <li>
                          <a
                            href="blog-details-sidebar-left.html"
                            className="image"
                          >
                            <img
                              src="assets/images/blog/recent-blog-img-4.jpg"
                              alt=""
                            />
                          </a>
                          <div className="content">
                            <h4 className="title">
                              <a href="blog-details-sidebar-left.html">
                                Everyone Should Know .
                              </a>{" "}
                            </h4>
                            <div className="post-meta">
                              <a href="#" className="date icon-space-right">
                                <i className="icofont-calendar" /> 15 Apr, 2021
                              </a>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    {/* End Sidebar Widget Single Item - Recent Post Widgets */}
                    {/* Start Sidebar Widget Single Item - Social Widgets */}
                    <div className="sidebar-widget-single-area ">
                      <h3 className="title">Follow Me</h3>
                      <ul className="social-link">
                        <li>
                          <a href="https://www.example.com" target="_blank">
                            <i className="icofont-facebook" />
                          </a>
                        </li>
                        <li>
                          <a href="https://www.example.com" target="_blank">
                            <i className="icofont-dribbble" />
                          </a>
                        </li>
                        <li>
                          <a href="https://www.example.com" target="_blank">
                            <i className="icofont-linkedin" />
                          </a>
                        </li>
                      </ul>
                    </div>
                    {/* End Sidebar Widget Single Item - Social Widgets */}
                  </div>
                  {/* End Sidebar Widget Area*/}
                </div>
                <div className="col-xl-8 col-lg-7">
                  {/*  Start Blog List  */}
                  <div className="blog-list">
                    {/* Start Blog List  Single Item*/}
                    <div className="blog-list-single-item">
                      <div className="inner-shape inner-shape-top-right" />
                      <a
                        href="blog-details-sidebar-left.html"
                        className="image"
                      >
                        <img
                          src="assets/images/blog/blog-feed-img-1.jpg"
                          alt=""
                        />
                      </a>
                      <div className="content">
                        <div className="post-meta-1">
                          <a href="#" className="catagory">
                            Business
                          </a>
                          <a href="#" className="date">
                            07 February, 2021
                          </a>
                        </div>
                        <h4 className="title">
                          <a href="#">
                            Don't wait until you officially started business.
                          </a>
                        </h4>
                        <div className="post-meta-2">
                          <a href="#" className="icon-space-right">
                            <i className="icofont-ui-user" />
                            Rolland Abell
                          </a>
                          <a href="#" className="icon-space-right">
                            <i className="icofont-heart" />
                            5,678
                          </a>
                          <a href="#" className="icon-space-right">
                            <i className="icofont-speech-comments" />
                            2,567
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* End Blog List  Single Item*/}
                    {/* Start Blog List  Single Item*/}
                    <div className="blog-list-single-item">
                      <div className="inner-shape inner-shape-top-right" />
                      <a
                        href="blog-details-sidebar-left.html"
                        className="image"
                      >
                        <img
                          src="assets/images/blog/blog-feed-img-2.jpg"
                          alt=""
                        />
                      </a>
                      <div className="content">
                        <div className="post-meta-1">
                          <a href="#" className="catagory">
                            Business
                          </a>
                          <a href="#" className="date">
                            07 February, 2021
                          </a>
                        </div>
                        <h4 className="title">
                          <a href="#">
                            Don't wait until you officially started business.
                          </a>
                        </h4>
                        <div className="post-meta-2">
                          <a href="#" className="icon-space-right">
                            <i className="icofont-ui-user" />
                            Rolland Abell
                          </a>
                          <a href="#" className="icon-space-right">
                            <i className="icofont-heart" />
                            5,678
                          </a>
                          <a href="#" className="icon-space-right">
                            <i className="icofont-speech-comments" />
                            2,567
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* End Blog List  Single Item*/}
                    {/* Start Blog List  Single Item*/}
                    <div className="blog-list-single-item">
                      <div className="inner-shape inner-shape-top-right" />
                      <a
                        href="blog-details-sidebar-left.html"
                        className="image"
                      >
                        <img
                          src="assets/images/blog/blog-feed-img-3.jpg"
                          alt=""
                        />
                      </a>
                      <div className="content">
                        <div className="post-meta-1">
                          <a href="#" className="catagory">
                            Business
                          </a>
                          <a href="#" className="date">
                            07 February, 2021
                          </a>
                        </div>
                        <h4 className="title">
                          <a href="#">
                            Don't wait until you officially started business.
                          </a>
                        </h4>
                        <div className="post-meta-2">
                          <a href="#" className="icon-space-right">
                            <i className="icofont-ui-user" />
                            Rolland Abell
                          </a>
                          <a href="#" className="icon-space-right">
                            <i className="icofont-heart" />
                            5,678
                          </a>
                          <a href="#" className="icon-space-right">
                            <i className="icofont-speech-comments" />
                            2,567
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* End Blog List  Single Item*/}
                    {/* Start Blog List  Single Item*/}
                    <div className="blog-list-single-item">
                      <div className="inner-shape inner-shape-top-right" />
                      <a
                        href="blog-details-sidebar-left.html"
                        className="image"
                      >
                        <img
                          src="assets/images/blog/blog-feed-img-4.jpg"
                          alt=""
                        />
                      </a>
                      <div className="content">
                        <div className="post-meta-1">
                          <a href="#" className="catagory">
                            Business
                          </a>
                          <a href="#" className="date">
                            07 February, 2021
                          </a>
                        </div>
                        <h4 className="title">
                          <a href="#">
                            Don't wait until you officially started business.
                          </a>
                        </h4>
                        <div className="post-meta-2">
                          <a href="#" className="icon-space-right">
                            <i className="icofont-ui-user" />
                            Rolland Abell
                          </a>
                          <a href="#" className="icon-space-right">
                            <i className="icofont-heart" />
                            5,678
                          </a>
                          <a href="#" className="icon-space-right">
                            <i className="icofont-speech-comments" />
                            2,567
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* End Blog List  Single Item*/}
                    {/* Start Blog List  Single Item*/}
                    <div className="blog-list-single-item">
                      <div className="inner-shape inner-shape-top-right" />
                      <a
                        href="blog-details-sidebar-left.html"
                        className="image"
                      >
                        <img
                          src="assets/images/blog/blog-feed-img-5.jpg"
                          alt=""
                        />
                      </a>
                      <div className="content">
                        <div className="post-meta-1">
                          <a href="#" className="catagory">
                            Business
                          </a>
                          <a href="#" className="date">
                            07 February, 2021
                          </a>
                        </div>
                        <h4 className="title">
                          <a href="#">
                            Don't wait until you officially started business.
                          </a>
                        </h4>
                        <div className="post-meta-2">
                          <a href="#" className="icon-space-right">
                            <i className="icofont-ui-user" />
                            Rolland Abell
                          </a>
                          <a href="#" className="icon-space-right">
                            <i className="icofont-heart" />
                            5,678
                          </a>
                          <a href="#" className="icon-space-right">
                            <i className="icofont-speech-comments" />
                            2,567
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* End Blog List  Single Item*/}
                    {/* Start Blog List  Single Item*/}
                    <div className="blog-list-single-item">
                      <div className="inner-shape inner-shape-top-right" />
                      <a
                        href="blog-details-sidebar-left.html"
                        className="image"
                      >
                        <img
                          src="assets/images/blog/blog-feed-img-6.jpg"
                          alt=""
                        />
                      </a>
                      <div className="content">
                        <div className="post-meta-1">
                          <a href="#" className="catagory">
                            Business
                          </a>
                          <a href="#" className="date">
                            07 February, 2021
                          </a>
                        </div>
                        <h4 className="title">
                          <a href="#">
                            Don't wait until you officially started business.
                          </a>
                        </h4>
                        <div className="post-meta-2">
                          <a href="#" className="icon-space-right">
                            <i className="icofont-ui-user" />
                            Rolland Abell
                          </a>
                          <a href="#" className="icon-space-right">
                            <i className="icofont-heart" />
                            5,678
                          </a>
                          <a href="#" className="icon-space-right">
                            <i className="icofont-speech-comments" />
                            2,567
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* End Blog List  Single Item*/}
                    {/* Start Blog List  Single Item*/}
                    <div className="blog-list-single-item">
                      <div className="inner-shape inner-shape-top-right" />
                      <a
                        href="blog-details-sidebar-left.html"
                        className="image"
                      >
                        <img
                          src="assets/images/blog/blog-feed-img-7.jpg"
                          alt=""
                        />
                      </a>
                      <div className="content">
                        <div className="post-meta-1">
                          <a href="#" className="catagory">
                            Business
                          </a>
                          <a href="#" className="date">
                            07 February, 2021
                          </a>
                        </div>
                        <h4 className="title">
                          <a href="#">
                            Don't wait until you officially started business.
                          </a>
                        </h4>
                        <div className="post-meta-2">
                          <a href="#" className="icon-space-right">
                            <i className="icofont-ui-user" />
                            Rolland Abell
                          </a>
                          <a href="#" className="icon-space-right">
                            <i className="icofont-heart" />
                            5,678
                          </a>
                          <a href="#" className="icon-space-right">
                            <i className="icofont-speech-comments" />
                            2,567
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* End Blog List  Single Item*/}
                  </div>
                  {/*  End Blog List   */}
                  <div className="d-flex justify-content-center justify-content-lg-start">
                    {/*  Start Pagination  */}
                    <div className="pagination">
                      <div className="row">
                        <div className="col-12">
                          <ul className="pagination-nav-list">
                            <li className="prev">
                              <a href="#">
                                <i className="icofont-double-left" />
                              </a>
                            </li>
                            <li>
                              <a href="#">1</a>
                            </li>
                            <li className="active">
                              <a href="#">2</a>
                            </li>
                            <li>
                              <a href="#">3</a>
                            </li>
                            <li className="next">
                              <a href="#">
                                <i className="icofont-double-right" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/*  Start Pagination  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ...::: End Blog List Section :::... */}
        {/* ...::: Start Company Logo Display Section :::... */}
        <div className="company-logo-display section-gap-tb-165">
          <div className="company-logo-display-box">
            <div className="container">
              <div className="row">
                <div className="col">
                  {/* Start Section Content */}
                  <div className="section-content pos-relative">
                    <span className="section-tag">Favourite Clients</span>
                    <h2 className="section-title">
                      Work With Trusted Comapny.
                    </h2>
                  </div>
                  {/* End Section Content */}
                </div>
              </div>
              <div className="company-logo-display-wrapper">
                <div className="row">
                  <div className="col">
                    <div className="company-logo-display-slider">
                      {/* Slider main container */}
                      <div className="swiper-container">
                        {/* Additional required wrapper */}
                        <div className="swiper-wrapper">
                          {/* Start Company Logo Slider Single Item */}
                          <div className="company-logo-single-item swiper-slide">
                            <a href="#" className="image">
                              <img
                                src="assets/images/company-logo/company-logo-1.png"
                                alt=""
                              />
                              <img
                                src="assets/images/company-logo/1.png"
                                alt=""
                              />
                            </a>
                          </div>
                          {/* End Company Logo Slider Single Item */}
                          {/* Start Company Logo Slider Single Item */}
                          <div className="company-logo-single-item swiper-slide">
                            <a href="#" className="image">
                              <img
                                src="assets/images/company-logo/company-logo-2.png"
                                alt=""
                              />
                              <img
                                src="assets/images/company-logo/2.png"
                                alt=""
                              />
                            </a>
                          </div>
                          {/* End Company Logo Slider Single Item */}
                          {/* Start Company Logo Slider Single Item */}
                          <div className="company-logo-single-item swiper-slide">
                            <a href="#" className="image">
                              <img
                                src="assets/images/company-logo/company-logo-3.png"
                                alt=""
                              />
                              <img
                                src="assets/images/company-logo/3.png"
                                alt=""
                              />
                            </a>
                          </div>
                          {/* End Company Logo Slider Single Item */}
                          {/* Start Company Logo Slider Single Item */}
                          <div className="company-logo-single-item swiper-slide">
                            <a href="#" className="image">
                              <img
                                src="assets/images/company-logo/company-logo-4.png"
                                alt=""
                              />
                              <img
                                src="assets/images/company-logo/4.png"
                                alt=""
                              />
                            </a>
                          </div>
                          {/* End Company Logo Slider Single Item */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ...::: End Company Logo Display Section :::... */}
        
        {/* material-scrolltop button */}
        <button className="material-scrolltop" type="button" />
      </main>
    </div>
  );
}
