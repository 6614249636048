import React, { Component } from "react";


//css files
import "./../style/css/style.css";
import "./../style/css/style.min.css";

import "./../style/css/plugins/animate.css";
import "./../style/css/plugins/plugins.min.css";
import "./../style/css/plugins/swiper-bundle.min.css";
import "./../style/css/plugins/venobox.min.css";

import "./../style/css/vendor/bootstrap.min.css";
import "./../style/css/vendor/icofont.min.css";
import "./../style/css/vendor/vendor.min.css";

//scss files
import "./../style/scss/style.scss";

import "./../style/scss/base/common/_button.scss";
import "./../style/scss/base/common/_form.scss";
import "./../style/scss/base/common/_measuremant.scss";
import "./../style/scss/base/common/_section.scss";
import "./../style/scss/base/common/_typhography.scss";
import "./../style/scss/base/_common.scss";
import "./../style/scss/base/_material-scroll-top.scss";
import "./../style/scss/base/_mixins.scss";
import "./../style/scss/base/_variables.scss";



import PropTypes from "prop-types";

const ProgressBar = ({ skillname, percentage }) => {
  const [style, setStyle] = React.useState({});

  setTimeout(() => {
    const newStyle = {
      opacity: 1,
      width: `${percentage}%`,
    };
    setStyle(newStyle);
  }, 1000);

  return (
    
      <div className="skill-progress-single-item">
        <span className="tag">{skillname}</span>
        <div className="skill-box">
          <div className="progress-line" style={style}>
            <span className="skill-percentage">{percentage}%</span>
          </div>
        </div>
      </div>
    
  );
};

ProgressBar.propTypes = {};

export default ProgressBar;
