import React from "react";
import companylogo1 from "./../images/company-logo/company-logo-cffi.png";
import companylogo2 from "./../images/company-logo/2.png";

export default function CompanyLogos() {
  return (
    <div className="company-logo-display section-mt-165 ">
      <div className="company-logo-display-box">
        <div className="container">
          <div className="row">
            <div className="col">
              {/* Start Section Content */}
              <div className="section-content pos-relative">
                <span className="section-tag">Favorite Clients</span>
                <h2 className="section-title">Work With Trusted Comapny.</h2>
              </div>
              {/* End Section Content */}
            </div>
          </div>
          <div className="company-logo-display-wrapper">
            <div className="row">
              <div className="col">
                <div className="company-logo-display-slider">
                  {/* Slider main container */}
                  <div className="swiper-container">
                    {/* Additional required wrapper */}
                    <div className="swiper-wrapper">
                      {/* Start Company Logo Slider Single Item */}
                      <div className="company-logo-single-item swiper-slide">
                        <a
                          href="https://comidaforfamilias.com"
                          className="image"
                        >
                          <img
                            src={companylogo1}
                            alt="Comida For Familias, Inc."
                          />
                          <img src="./../images/company-logo/1.png" alt="" />
                        </a>
                      </div>
                      {/* End Company Logo Slider Single Item */}
                      {/* Start Company Logo Slider Single Item */}
                      <div className="company-logo-single-item swiper-slide">
                        <a href="#" className="image">
                          <img src={companylogo1} alt="" />
                          <img src={companylogo2} alt="" />
                        </a>
                      </div>
                      {/* End Company Logo Slider Single Item */}
                      {/* Start Company Logo Slider Single Item */}
                      <div className="company-logo-single-item swiper-slide">
                        <a href="#" className="image">
                          <img
                            src="./../images/company-logo/company-logo-3.png"
                            alt=""
                          />
                          <img src="./../images/company-logo/3.png" alt="" />
                        </a>
                      </div>
                      {/* End Company Logo Slider Single Item */}
                      {/* Start Company Logo Slider Single Item */}
                      <div className="company-logo-single-item swiper-slide">
                        <a href="#" className="image">
                          <img
                            src="./../images/company-logo/company-logo-4.png"
                            alt=""
                          />
                          <img src="./../images/company-logo/4.png" alt="" />
                        </a>
                      </div>
                      {/* End Company Logo Slider Single Item */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
