import { useState } from "react";
import { BIO_SWITCHER } from "../constants/constants";


function Tabs() {
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  return (
    <div>
      <div className="resume-info-display-section  section-gap-tb-165 section-bg">
        <div className="resume-info-display-box">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <ul className="resume-tab nav">
                  <li className="nav-item">
                    <button
                      className={
                        toggleState === 1 ? "nav-link active" : "nav-link"
                      }
                      onClick={() => toggleTab(1)}
                    >
                      Education
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className={
                        toggleState === 2 ? "nav-link active" : "nav-link"
                      }
                      onClick={() => toggleTab(2)}
                    >
                      Experience
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <div className="resume-info-display-wrapper">
              <div className="row">
                <div className="col-12">
                  <div className="tab-content" id="myTabContent">
                    <div
                      className={toggleState === 1 ? "tab-pane fade show active" : "tab-pane fade"}
                      id="education-tab"
                      role="tabpanel"
                    >
                      <ul className="resume-list">
                        {/* Start Resume Tab List Single Item */}

                      {BIO_SWITCHER.education.map((item, index)=>{
                       return(
                           <li className="resume-tab-list-single-item">
                               <div className="content">
                                   <div className="left">
                                       <span className="year">{item.years}</span>
                                   </div>
                                   <div className="right">
                                       <h3 className="title">
                                           {item.degree}
                                       </h3>
                                       <span className="institute-name">
                                           {item.university}
                                       </span>
                                       <p>
                                           {item.content}
                                       </p>
                                   </div>
                               </div>
                           </li>
                       )   
                      })}
                        {/* End Resume Tab List Single Item */}
                      </ul>
                    </div>
                    <div
                      className={toggleState === 2 ? "tab-pane fade show active" : "tab-pane fade"}
                      id="experience-tab"
                      role="tabpanel"
                    >
                      <ul className="resume-list">
                        {/* Start Resume Tab List Single Item */}
                        {BIO_SWITCHER.experience.map((item, index)=>{
                       return(
                           <li className="resume-tab-list-single-item">
                               <div className="content">
                                   <div className="left">
                                       <span className="year">{item.years}</span>
                                   </div>
                                   <div className="right">
                                       <h3 className="title">
                                           {item.position}
                                       </h3>
                                       <span className="institute-name">
                                           {item.company}
                                       </span>
                                       <p>
                                           {item.content}
                                       </p>
                                   </div>
                               </div>
                           </li>
                       )   
                      })}
                        
                        {/* End Resume Tab List Single Item */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div>
        <button
          className={toggleState === 1 ? "nav-link active" : "nav-link"}
          onClick={() => toggleTab(1)}
        >
          TAB 1
        </button>
        <button
          className={toggleState === 2 ? "nav-link active" : "nav-link"}
          onClick={() => toggleTab(2)}
        >
          TAB 2
        </button>
      </div>

      <div className="content-tabs">
        <div
          className={toggleState === 1 ? "tab-pane fade show active" : "tab-pane fade"}
        >
          <h2>Content 1</h2>
          <hr></hr>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati
            praesentium incidunt quia aspernatur quasi quidem facilis quo nihil
            vel voluptatum?
          </p>
        </div>

        <div
          className={toggleState === 2 ? "tab-pane fade show active" : "tab-pane fade"}
        >
          <h2>Content 2</h2>
          <hr></hr>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente
            voluptatum qui adipisci.
          </p>
        </div>
      </div> */}
    </div>
  );
}

export default Tabs;
