import React, { Component } from "react";

class SolutionList extends Component {
  render() {
    return (
      <div>
        <main className="main-wrapper">
          {/* ...::: Start Breadcrumb Section :::... */}
          <div className="breadcrumb-section section-bg overflow-hidden pos-relative">
            <div className="breadcrumb-shape-top-left" />
            <div className="breadcrumb-shape-bottom-right" />
            <div className="breadcrumb-box">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="breadcrumb-content">
                      <h2 className="title">Solutions</h2>
                      <ul className="breadcrumb-link">
                        <li>
                          <a href="Solution-list.html">Home</a>
                        </li>
                        <li className="active" aria-current="page">
                          Solutions List
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ...::: End Breadcrumb Section :::... */}
          {/* .....:::::: Start Solution Section :::::.... */}
          <div className="Solution-section section-gap-tb-165">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="Solutions-gallery-filter-nav">
                    <button
                      className="btn btn-outline-secondary active"
                      data-filter="*"
                    >
                      All
                    </button>
                    <button
                      className="btn btn-outline-secondary"
                      data-filter=".html"
                    >
                      HTML
                    </button>
                    <button
                      className="btn btn-outline-secondary"
                      data-filter=".wordpress"
                    >
                      Wordpress
                    </button>
                    <button
                      className="btn btn-outline-secondary"
                      data-filter=".shopify"
                    >
                      Shopify
                    </button>
                    <button
                      className="btn btn-outline-secondary"
                      data-filter=".seo"
                    >
                      SEO
                    </button>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="Solutions-wrapper-gallery-content">
                    <div className="row">
                      <div className="col-md-6 filter-item html">
                        {/* Start Solution Box Single Item */}
                        <div className="Solution-box-single-item">
                          <div className="img-box">
                            <div className="bg-overlay" />
                            <div className="bg-image">
                              <img
                                src="assets/images/Solution/Solution-slider-img-1.jpg"
                                alt=""
                              />
                            </div>
                            <div className="image">
                              <img
                                src="assets/images/Solution/Solution-slider-img-1.jpg"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="content">
                            <h4 className="title">
                              <a href="Solution-details.html">
                                Givest - Non Profit PSD Template
                              </a>
                            </h4>
                            <ul className="catagory-nav-item">
                              <li>
                                <a href>Chairty</a>
                              </li>
                              <li>
                                <a href>Fund Rising</a>
                              </li>
                              <li>
                                <a href>Non Profit</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        {/* End Solution Box Single Item */}
                      </div>
                      <div className="col-md-6 filter-item industwordpressrial">
                        {/* Start Solution Box Single Item */}
                        <div className="Solution-box-single-item">
                          <div className="img-box">
                            <div className="bg-overlay" />
                            <div className="bg-image">
                              <img
                                src="assets/images/Solution/Solution-slider-img-2.jpg"
                                alt=""
                              />
                            </div>
                            <div className="image">
                              <img
                                src="assets/images/Solution/Solution-slider-img-2.jpg"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="content">
                            <h4 className="title">
                              <a href="Solution-details.html">
                                Musion – Gardening HTML Template
                              </a>
                            </h4>
                            <ul className="catagory-nav-item">
                              <li>
                                <a href>Chairty</a>
                              </li>
                              <li>
                                <a href>Fund Rising</a>
                              </li>
                              <li>
                                <a href>Non Profit</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        {/* End Solution Box Single Item */}
                      </div>
                      <div className="col-md-6 filter-item shopify">
                        {/* Start Solution Box Single Item */}
                        <div className="Solution-box-single-item">
                          <div className="img-box">
                            <div className="bg-overlay" />
                            <div className="bg-image">
                              <img
                                src="assets/images/Solution/Solution-slider-img-3.jpg"
                                alt=""
                              />
                            </div>
                            <div className="image">
                              <img
                                src="assets/images/Solution/Solution-slider-img-3.jpg"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="content">
                            <h4 className="title">
                              <a href="Solution-details.html">
                                SEOLLY - SEO Marketing &amp; Digital Agency
                              </a>
                            </h4>
                            <ul className="catagory-nav-item">
                              <li>
                                <a href>Chairty</a>
                              </li>
                              <li>
                                <a href>Fund Rising</a>
                              </li>
                              <li>
                                <a href>Non Profit</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        {/* End Solution Box Single Item */}
                      </div>
                      <div className="col-md-6 filter-item seo">
                        {/* Start Solution Box Single Item */}
                        <div className="Solution-box-single-item">
                          <div className="img-box">
                            <div className="bg-overlay" />
                            <div className="bg-image">
                              <img
                                src="assets/images/Solution/Solution-slider-img-4.jpg"
                                alt=""
                              />
                            </div>
                            <div className="image">
                              <img
                                src="assets/images/Solution/Solution-slider-img-4.jpg"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="content">
                            <h4 className="title">
                              <a href="Solution-details.html">
                                Virtuf - Creative Agency Bootstrap 5 Template
                              </a>
                            </h4>
                            <ul className="catagory-nav-item">
                              <li>
                                <a href>Chairty</a>
                              </li>
                              <li>
                                <a href>Fund Rising</a>
                              </li>
                              <li>
                                <a href>Non Profit</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        {/* End Solution Box Single Item */}
                      </div>
                      <div className="col-md-6 filter-item html">
                        {/* Start Solution Box Single Item */}
                        <div className="Solution-box-single-item">
                          <div className="img-box">
                            <div className="bg-overlay" />
                            <div className="bg-image">
                              <img
                                src="assets/images/Solution/Solution-slider-img-5.jpg"
                                alt=""
                              />
                            </div>
                            <div className="image">
                              <img
                                src="assets/images/Solution/Solution-slider-img-5.jpg"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="content">
                            <h4 className="title">
                              <a href="Solution-details.html">
                                Tourve - Travel Agency Bootstrap 5 Template
                              </a>
                            </h4>
                            <ul className="catagory-nav-item">
                              <li>
                                <a href>Chairty</a>
                              </li>
                              <li>
                                <a href>Fund Rising</a>
                              </li>
                              <li>
                                <a href>Non Profit</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        {/* End Solution Box Single Item */}
                      </div>
                      <div className="col-md-6 filter-item wordpress">
                        {/* Start Solution Box Single Item */}
                        <div className="Solution-box-single-item">
                          <div className="img-box">
                            <div className="bg-overlay" />
                            <div className="bg-image">
                              <img
                                src="assets/images/Solution/Solution-slider-img-6.jpg"
                                alt=""
                              />
                            </div>
                            <div className="image">
                              <img
                                src="assets/images/Solution/Solution-slider-img-6.jpg"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="content">
                            <h4 className="title">
                              <a href="Solution-details.html">
                                Induste - Industrial &amp; Factory Bootstrap 5
                                Template
                              </a>
                            </h4>
                            <ul className="catagory-nav-item">
                              <li>
                                <a href>Chairty</a>
                              </li>
                              <li>
                                <a href>Fund Rising</a>
                              </li>
                              <li>
                                <a href>Non Profit</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        {/* End Solution Box Single Item */}
                      </div>
                      <div className="col-md-6 filter-item shopify">
                        {/* Start Solution Box Single Item */}
                        <div className="Solution-box-single-item">
                          <div className="img-box">
                            <div className="bg-overlay" />
                            <div className="bg-image">
                              <img
                                src="assets/images/Solution/Solution-slider-img-7.jpg"
                                alt=""
                              />
                            </div>
                            <div className="image">
                              <img
                                src="assets/images/Solution/Solution-slider-img-7.jpg"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="content">
                            <h4 className="title">
                              <a href="Solution-details.html">
                                Consor - Multi Purpose One Page PSD Template
                              </a>
                            </h4>
                            <ul className="catagory-nav-item">
                              <li>
                                <a href>Chairty</a>
                              </li>
                              <li>
                                <a href>Fund Rising</a>
                              </li>
                              <li>
                                <a href>Non Profit</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        {/* End Solution Box Single Item */}
                      </div>
                      <div className="col-md-6 filter-item seo">
                        {/* Start Solution Box Single Item */}
                        <div className="Solution-box-single-item">
                          <div className="img-box">
                            <div className="bg-overlay" />
                            <div className="bg-image">
                              <img
                                src="assets/images/Solution/Solution-slider-img-8.jpg"
                                alt=""
                              />
                            </div>
                            <div className="image">
                              <img
                                src="assets/images/Solution/Solution-slider-img-8.jpg"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="content">
                            <h4 className="title">
                              <a href="Solution-details.html">
                                Asore - Business Bootstrap 5 Template
                              </a>
                            </h4>
                            <ul className="catagory-nav-item">
                              <li>
                                <a href>Chairty</a>
                              </li>
                              <li>
                                <a href>Fund Rising</a>
                              </li>
                              <li>
                                <a href>Non Profit</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        {/* End Solution Box Single Item */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* .....:::::: End Solution Section :::::.... */}
          
          {/* material-scrolltop button */}
          <button className="material-scrolltop" type="button" />
        </main>
      </div>
    );
  }
}

export default SolutionList;
<main className="main-wrapper">
  {/* ...::: Start Breadcrumb Section :::... */}
  <div className="breadcrumb-section section-bg overflow-hidden pos-relative">
    <div className="breadcrumb-shape-top-left" />
    <div className="breadcrumb-shape-bottom-right" />
    <div className="breadcrumb-box">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="breadcrumb-content">
              <h2 className="title">Solutions</h2>
              <ul className="breadcrumb-link">
                <li>
                  <a href="Solution-list.html">Home</a>
                </li>
                <li className="active" aria-current="page">
                  Solutions List
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* ...::: End Breadcrumb Section :::... */}
  {/* .....:::::: Start Solution Section :::::.... */}
  <div className="Solution-section section-gap-tb-165">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="Solutions-gallery-filter-nav">
            <button
              className="btn btn-outline-secondary active"
              data-filter="*"
            >
              All
            </button>
            <button className="btn btn-outline-secondary" data-filter=".html">
              HTML
            </button>
            <button
              className="btn btn-outline-secondary"
              data-filter=".wordpress"
            >
              Wordpress
            </button>
            <button
              className="btn btn-outline-secondary"
              data-filter=".shopify"
            >
              Shopify
            </button>
            <button className="btn btn-outline-secondary" data-filter=".seo">
              SEO
            </button>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="Solutions-wrapper-gallery-content">
            <div className="row">
              <div className="col-md-6 filter-item html">
                {/* Start Solution Box Single Item */}
                <div className="Solution-box-single-item">
                  <div className="img-box">
                    <div className="bg-overlay" />
                    <div className="bg-image">
                      <img
                        src="assets/images/Solution/Solution-slider-img-1.jpg"
                        alt=""
                      />
                    </div>
                    <div className="image">
                      <img
                        src="assets/images/Solution/Solution-slider-img-1.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="content">
                    <h4 className="title">
                      <a href="Solution-details.html">
                        Givest - Non Profit PSD Template
                      </a>
                    </h4>
                    <ul className="catagory-nav-item">
                      <li>
                        <a href>Chairty</a>
                      </li>
                      <li>
                        <a href>Fund Rising</a>
                      </li>
                      <li>
                        <a href>Non Profit</a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* End Solution Box Single Item */}
              </div>
              <div className="col-md-6 filter-item industwordpressrial">
                {/* Start Solution Box Single Item */}
                <div className="Solution-box-single-item">
                  <div className="img-box">
                    <div className="bg-overlay" />
                    <div className="bg-image">
                      <img
                        src="assets/images/Solution/Solution-slider-img-2.jpg"
                        alt=""
                      />
                    </div>
                    <div className="image">
                      <img
                        src="assets/images/Solution/Solution-slider-img-2.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="content">
                    <h4 className="title">
                      <a href="Solution-details.html">
                        Musion – Gardening HTML Template
                      </a>
                    </h4>
                    <ul className="catagory-nav-item">
                      <li>
                        <a href>Chairty</a>
                      </li>
                      <li>
                        <a href>Fund Rising</a>
                      </li>
                      <li>
                        <a href>Non Profit</a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* End Solution Box Single Item */}
              </div>
              <div className="col-md-6 filter-item shopify">
                {/* Start Solution Box Single Item */}
                <div className="Solution-box-single-item">
                  <div className="img-box">
                    <div className="bg-overlay" />
                    <div className="bg-image">
                      <img
                        src="assets/images/Solution/Solution-slider-img-3.jpg"
                        alt=""
                      />
                    </div>
                    <div className="image">
                      <img
                        src="assets/images/Solution/Solution-slider-img-3.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="content">
                    <h4 className="title">
                      <a href="Solution-details.html">
                        SEOLLY - SEO Marketing &amp; Digital Agency
                      </a>
                    </h4>
                    <ul className="catagory-nav-item">
                      <li>
                        <a href>Chairty</a>
                      </li>
                      <li>
                        <a href>Fund Rising</a>
                      </li>
                      <li>
                        <a href>Non Profit</a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* End Solution Box Single Item */}
              </div>
              <div className="col-md-6 filter-item seo">
                {/* Start Solution Box Single Item */}
                <div className="Solution-box-single-item">
                  <div className="img-box">
                    <div className="bg-overlay" />
                    <div className="bg-image">
                      <img
                        src="assets/images/Solution/Solution-slider-img-4.jpg"
                        alt=""
                      />
                    </div>
                    <div className="image">
                      <img
                        src="assets/images/Solution/Solution-slider-img-4.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="content">
                    <h4 className="title">
                      <a href="Solution-details.html">
                        Virtuf - Creative Agency Bootstrap 5 Template
                      </a>
                    </h4>
                    <ul className="catagory-nav-item">
                      <li>
                        <a href>Chairty</a>
                      </li>
                      <li>
                        <a href>Fund Rising</a>
                      </li>
                      <li>
                        <a href>Non Profit</a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* End Solution Box Single Item */}
              </div>
              <div className="col-md-6 filter-item html">
                {/* Start Solution Box Single Item */}
                <div className="Solution-box-single-item">
                  <div className="img-box">
                    <div className="bg-overlay" />
                    <div className="bg-image">
                      <img
                        src="assets/images/Solution/Solution-slider-img-5.jpg"
                        alt=""
                      />
                    </div>
                    <div className="image">
                      <img
                        src="assets/images/Solution/Solution-slider-img-5.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="content">
                    <h4 className="title">
                      <a href="Solution-details.html">
                        Tourve - Travel Agency Bootstrap 5 Template
                      </a>
                    </h4>
                    <ul className="catagory-nav-item">
                      <li>
                        <a href>Chairty</a>
                      </li>
                      <li>
                        <a href>Fund Rising</a>
                      </li>
                      <li>
                        <a href>Non Profit</a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* End Solution Box Single Item */}
              </div>
              <div className="col-md-6 filter-item wordpress">
                {/* Start Solution Box Single Item */}
                <div className="Solution-box-single-item">
                  <div className="img-box">
                    <div className="bg-overlay" />
                    <div className="bg-image">
                      <img
                        src="assets/images/Solution/Solution-slider-img-6.jpg"
                        alt=""
                      />
                    </div>
                    <div className="image">
                      <img
                        src="assets/images/Solution/Solution-slider-img-6.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="content">
                    <h4 className="title">
                      <a href="Solution-details.html">
                        Induste - Industrial &amp; Factory Bootstrap 5 Template
                      </a>
                    </h4>
                    <ul className="catagory-nav-item">
                      <li>
                        <a href>Chairty</a>
                      </li>
                      <li>
                        <a href>Fund Rising</a>
                      </li>
                      <li>
                        <a href>Non Profit</a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* End Solution Box Single Item */}
              </div>
              <div className="col-md-6 filter-item shopify">
                {/* Start Solution Box Single Item */}
                <div className="Solution-box-single-item">
                  <div className="img-box">
                    <div className="bg-overlay" />
                    <div className="bg-image">
                      <img
                        src="assets/images/Solution/Solution-slider-img-7.jpg"
                        alt=""
                      />
                    </div>
                    <div className="image">
                      <img
                        src="assets/images/Solution/Solution-slider-img-7.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="content">
                    <h4 className="title">
                      <a href="Solution-details.html">
                        Consor - Multi Purpose One Page PSD Template
                      </a>
                    </h4>
                    <ul className="catagory-nav-item">
                      <li>
                        <a href>Chairty</a>
                      </li>
                      <li>
                        <a href>Fund Rising</a>
                      </li>
                      <li>
                        <a href>Non Profit</a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* End Solution Box Single Item */}
              </div>
              <div className="col-md-6 filter-item seo">
                {/* Start Solution Box Single Item */}
                <div className="Solution-box-single-item">
                  <div className="img-box">
                    <div className="bg-overlay" />
                    <div className="bg-image">
                      <img
                        src="assets/images/Solution/Solution-slider-img-8.jpg"
                        alt=""
                      />
                    </div>
                    <div className="image">
                      <img
                        src="assets/images/Solution/Solution-slider-img-8.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="content">
                    <h4 className="title">
                      <a href="Solution-details.html">
                        Asore - Business Bootstrap 5 Template
                      </a>
                    </h4>
                    <ul className="catagory-nav-item">
                      <li>
                        <a href>Chairty</a>
                      </li>
                      <li>
                        <a href>Fund Rising</a>
                      </li>
                      <li>
                        <a href>Non Profit</a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* End Solution Box Single Item */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* .....:::::: End Solution Section :::::.... */}
  
  {/* material-scrolltop button */}
  <button className="material-scrolltop" type="button" />
</main>;
