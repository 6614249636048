import React from "react";

//css files
import "./../style/css/style.css";
import "./../style/css/style.min.css";

import "./../style/css/plugins/animate.css";
import "./../style/css/plugins/plugins.min.css";
import "./../style/css/plugins/swiper-bundle.min.css";
import "./../style/css/plugins/venobox.min.css";

import "./../style/css/vendor/bootstrap.min.css";
import "./../style/css/vendor/icofont.min.css";
import "./../style/css/vendor/vendor.min.css";

//scss files
import "./../style/scss/style.scss";

import "./../style/scss/base/common/_button.scss";
import "./../style/scss/base/common/_form.scss";
import "./../style/scss/base/common/_measuremant.scss";
import "./../style/scss/base/common/_section.scss";
import "./../style/scss/base/common/_typhography.scss";
import "./../style/scss/base/_common.scss";
import "./../style/scss/base/_material-scroll-top.scss";
import "./../style/scss/base/_mixins.scss";
import "./../style/scss/base/_variables.scss";
import "./../style/scss/components/_carousel.scss";

//components
import CompanyLogos from "../components/CompanyLogos";
import Carousel from "../components/Carousel";
import ServiceGrid from "../components/ServiceGrid";
import CounterDisplay from "../components/CounterDisplay";
import SolutionsDisplay from "../components/SolutionsDisplay";
import BlogFeedDisplay from "../components/BlogFeedDisplay";


//images
import potrait from "./../images/portrait/ceo-portrait1.png";
import companylogo1 from "./../images/company-logo/company-logo-cffi.png";

import { Link } from "react-router-dom";
import ProgressBar from "../components/ProgressBar";

//docs
import resumepdf from "../docs/am_resume.pdf";
import resumedocx from "../docs/am_resume.docx";

//typeform widgets
import { widgets, PopupButton } from "@typeform/embed-react";

function Home() {
  return (
    <main className="main-wrapper">
      {/* ...::: Start Hero Section :::... */}
      <div className="hero-section section-dark-blue-bg">
        <div className="hero-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-xxl-7">
                <div className="hero-content">
                  <h3 className="title-big">Hello! I’m</h3>
                  <h2 className="title-large">
                    <span className="shape-mark">Antonio</span>
                  </h2>
                  <p>
                    Director and CEO, tech evangelist, consultant, model, and
                    entrepreneur.
                  </p>
                  {/* <a
                    href={resumepdf}
                    className="btn btn-xl btn-outline-one icon-space-left"
                  >
                    Booking Me<i className="icofont-download" />
                  </a> */}
                  <PopupButton
                    id="GE1XlP0I"
                    style={{ fontSize: 20 }}
                    className="btn btn-xl btn-outline-one icon-space-left"
                  >
                    Book Me
                  </PopupButton>
{/*
                  <div className="video-link">
                    <a
                      className="wave-btn"
                      href="https://youtu.be/MKjhBO2xQzg"
                      data-autoplay="true"
                      data-vbtype="video"
                    >
                      <div className="ripple">
                        <i className="icofont-ui-play" />
                      </div>
                    </a>
                    <span className="video-text"> Watch Video</span>
                  </div>
                  */}

                </div>
              </div>
            </div>
          </div>
          <div className="hero-shape hero-top-shape">
            <span />
            <span />
            <span />
          </div>
          <div className="hero-shape hero-bottom-shape">
            <span />
            <span />
            <span />
          </div>
          <div className="hero-portrait">
            <div className="image">
              <img
                className="img-fluid"
                src={potrait}
                alt="Potrait of Antonio Mendieta"
              />
              <div className="image-half-round-shape" />
              <div className="social-link">
                <a
                  href="https://www.facebook.com/tony.mendieta1"
                  target="_blank"
                >
                  <i className="icofont-facebook" />
                </a>
                <a
                  href="https://www.instagram.com/antoniomendieta_ceo/"
                  target="_blank"
                >
                  <i className="icofont-instagram" />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCz2IrG313q3OCbGbSwwzCug"
                  target="_blank"
                >
                  <i className="icofont-youtube" />
                </a>
                <a
                  href="https://www.linkedin.com/in/antoniomendieta/"
                  target="_blank"
                >
                  <i className="icofont-linkedin" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ...::: End Hero Section :::... */}

      {/* ...::: Start Service Display Section :::... */}
      <div className="service-display-section section-gap-tb-165 pos-relative">
        <div className="container">
          <div className="row">
            <div className="col-12">
              {/* Start Section Content */}
              <div className="section-content">
                <span className="section-tag">My Services</span>
                <h2 className="section-title">Services I Provide.</h2>
              </div>
              {/* End Section Content */}
            </div>
          </div>
        </div>
        {/* Start Service Section Wrapper */}
        {/* <Carousel/> We will add it back eventually*/}
        <ServiceGrid />

        {/* End Service Section Wrapper */}
      </div>
      {/* ...::: End Service Display Section :::... */}

      {/* ...::: Start Skill Display Section :::... */}
      <div className="skill-display-section section-gap-tb-165 section-bg pos-relative">
        <div className="skill-display-section-box">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-6 col-xxl-5">
                {/* Start Section Content */}
                <div className="section-content">
                  <span className="section-tag">Special Skills</span>
                  <h2 className="section-title">My Special Skills.</h2>
                  <a
                    href={resumepdf}
                    className="btn btn-xl btn-outline-one icon-space-left"
                  >
                    Get Resume <i className="icofont-download" />
                  </a>
                </div>
                {/* End Section Content */}
              </div>
              <div className="col-xl-6 col-xxl-6 offset-xxl-1">
                {/* Start Skill Display Wrapper */}
                <div className="skill-display-wrapper">
                  <ProgressBar skillname="Communication" percentage="100" />
                  <ProgressBar skillname="Leadership" percentage="100" />
                  <ProgressBar skillname="Teamwork" percentage="100" />
                  <ProgressBar skillname="Flexibility" percentage="80" />
                  <ProgressBar
                    skillname="Computer Knowledge"
                    percentage="100"
                  />
                </div>
                {/* End Skill Display Wrapper */}
              </div>
            </div>
          </div>
        </div>
        <div className="skill-display-shape" />
      </div>
      {/* ...::: End Skill Display Section :::... */}

      {/* ...::: Start Counter Display Section :::... */}
      {/* <CounterDisplay /> */}
      {/* ...::: End Counter Display Section :::... */}

      {/* ...::: Start Solution Display Section :::... */}
      {/* <SolutionsDisplay /> */}
      {/* ...::: End Solution Display Section :::... */}

      {/* ...::: Start Testimonial Display Section :::... */}
      {/* ...::: End Testimonial Display Section :::... */}

      {/* ...::: Start Company Logo Display Section :::... */}
      <CompanyLogos />
      {/* ...::: End Company Logo Display Section :::... */}

      {/* ...::: Start Blog Feed Display Section :::... */}
      {/* <BlogFeedDisplay /> */}
      {/* ...::: End Blog Feed Display Section :::... */}

      {/* material-scrolltop button */}
      <button className="material-scrolltop" type="button" />
    </main>
  );
}

export default Home;
