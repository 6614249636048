import React, { Component } from "react";
import CompanyLogos from "../components/CompanyLogos";
import CounterDisplay from "../components/CounterDisplay";

import ServiceGrid from "../components/ServiceGrid";

class Services extends Component {
  render() {
    return (
      <div>
        <main className="main-wrapper">
          {/* ...::: Start Breadcrumb Section :::... */}
          <div className="breadcrumb-section section-bg overflow-hidden pos-relative">
            <div className="breadcrumb-shape-top-left" />
            <div className="breadcrumb-shape-bottom-right" />
            <div className="breadcrumb-box">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="breadcrumb-content">
                      <h2 className="title">Services</h2>
                      <ul className="breadcrumb-link">
                        <li>
                          <a href="service-list.html">Service</a>
                        </li>
                        <li className="active" aria-current="page">
                          Service List
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ...::: End Breadcrumb Section :::... */}
          
          {/* ...::: Start Service List Section :::... */}
          <ServiceGrid/>
          {/* ...::: End Service List Section :::... */}

          {/* ...::: Start Counter Display Section :::... */}
          {/* <CounterDisplay/> */}
          {/* ...::: End Counter Display Section :::... */}
          
          {/* ...::: Start Company Logo Display Section :::... */}
          <CompanyLogos/>
          {/* ...::: End Company Logo Display Section :::... */}
          
          {/* material-scrolltop button */}
          <button className="material-scrolltop" type="button" />
        </main>
      </div>
    );
  }
}

export default Services;
