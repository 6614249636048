import { pic1 } from "./../images/solution/solution-slider-img-1.jpg";
//assets/images/icon/service-icon-1.png
import { Link } from "react-router-dom";

export const PICTURES = [
  {
    id: 0,
    photo: require("./../images/gallery/blue_suit.png"),
    photobg: require("./../images/solution/solution-slider-img-1.jpg"),
  },
  {
    id: 1,
    photo: require("./../images/solution/solution-slider-img-1.jpg"),
    photobg: require("./../images/solution/solution-slider-img-1.jpg"),
  },
  {
    id: 2,
    photo: require("./../images/solution/solution-slider-img-1.jpg"),
    photobg: require("./../images/solution/solution-slider-img-1.jpg"),
  },
  {
    id: 3,
    photo: require("./../images/solution/solution-slider-img-1.jpg"),
    photobg: require("./../images/solution/solution-slider-img-1.jpg"),
  },
];

export const BIO_SWITCHER = {
  tabs: [1, 2, 3],
  education: [
    {
      degree: "Bachelor in Game Design and Simulation",
      university: "Albright College",
      years: "2014 - 2018",
      content: `A combination of betwee Albright's Computer Science and Digital Media program, this 
        major thaught software engineering principles to implement into game and simulations 
        technologies. Courses involved within this progam include programming, artificial intelligence,
        app development, game engine development, data structures and algorithms, and digital illustration and design.`,
    },
  ],
  experience: [
    {
      position: "Director and CEO",
      company: "Comida For Familias, Inc.",
      years: "2019 - current",
      content: `Determines and formulates policies and provides overall direction for the organization within guidelines set up by a board of directors. 
      Antonio has directed and coordinated the organization's financial or budget activited to fund operations, maximize investments or increase efficiency 
      Led 10 projects through the software development life cycle: Design, Coding, Testing, Deployment and Maintenance. Provided guidance to skilled individuals 
      who specialize in UX/UI design and research, and webapp/app development.`,
    },
    {
      position: "Customer Technical Support",
      company: "West-Com Nurse Call Systems, Inc. ",
      years: "2019 - 2021",
      content:
        "Confer with customers by telephone to provide information about nurse call systems installation and repairs. Ensured that appropriate changes were madae to resolve customers' problems.",
    },
  ],
};

export const SERVICE_CARDS = [
  {
    title: "Tech Envangilist",
    href: "service-details.html",
    icon: "",
    alt: "",
    list: [
      "Landing Pages",
      "User Flow",
      "Wireframing",
      "Protoyping",
      "Mobile App Design",
    ],
  },
  {
    title: "UI/UX Design",
    href: "service-details.html",
    icon: "",
    alt: "",
    list: [
      "Landing Pages",
      "User Flow",
      "Wireframing",
      "Protoyping",
      "Mobile App Design",
    ],
  },
  {
    title: "Development",
    href: "service-details.html",
    icon: "",
    alt: "",
    list: ["HTML/CSS", "JavaScript", "Animation", "WordPress", "React"],
  },
  {
    title: "Illustration",
    href: "service-details.html",
    icon: "",
    alt: "",
    list: [
      "Character Design",
      "Icon Set",
      "Illustration Guide",
      "Illustration Set",
      "Motion Graphic",
    ],
  },
  {
    title: "SEO Marketing",
    href: "service-details.html",
    icon: "",
    alt: "",
    list: [
      "White Hat SEO",
      "Black Hat SEO",
      "Grey Hat SEO",
      "Negative SEO",
      "On-Page SEO",
    ],
  },
  {
    title: "Data Analysis",
    href: "service-details.html",
    icon: "",
    alt: "",
    list: [
      "Diagnostic Analysis",
      "Predictive Analysis",
      "Prescriptive Analysis",
      "Risk Assessment",
      "Sales Forecasting",
    ],
  },
  {
    title: "Brand Identity",
    href: "service-details.html",
    icon: "",
    alt: "",
    list: [
      "Brand Architecture",
      "Brand Authenticity",
      "Brand Awareness",
      "Brand Management",
      "Brand Metrics",
    ],
  },
];

export const SOLUTIONS = [
  {
    bgimg: require("./../images/solution/solution-slider-img-1.jpg"),
    bgimgalt: "",
    img: require("./../images/gallery/blue_suit.png"),
    imgalt: "",
    href: "link here",
    title: "ENTER TITLE HERE",
    categorylist: [
      <Link to="/charity">Charity</Link>,
      <Link to="/fundraising">Fundraising</Link>,
      <Link to="/nonprofit">Nonprofit</Link>,
    ],
  },
];
