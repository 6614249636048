import React from "react";

function TestimonialDisplay(props) {
  return (
    <div className="testimonial-display-section section-gap-tb-165 section-bg">
      <div className="testimonial-display-box d-flex flex-column align-items-center d-xl-block pos-relative">
        <div className="container-xl overflow-hidden">
          <div className="row">
            <div className="col d-xl-flex justify-content-xl-end">
              {/* Start Section Content */}
              <div className="section-content pos-relative">
                <span className="section-tag">Testimonial</span>
                <h2 className="section-title">Satisfied Clients Say</h2>
              </div>
              {/* End Section Content */}
            </div>
          </div>
          <div className="testimonial-display-wrapper">
            <div className="row">
              <div className="col-12">
                <div className="testimonial-display-slider">
                  {/* Swiper */}
                  <div className="swiper-container">
                    <div className="swiper-wrapper">
                      {/* Start testimonial Slider Single Item */}
                      <div className="testimonial-slider-single-item swiper-slide">
                        <div className="inner-shape inner-shape-top-right" />
                        <div className="content">
                          <span className="icon">“</span>
                          <p className="text">
                            Lorem Ipsum simpy dummy text of the printing and
                            types industry has been the industr standard dummy.
                          </p>
                          <div className="info">
                            <div className="author">
                              <h4 className="name">Raleigh Friend</h4>
                              <span className="designation">CEO, Seoly</span>
                            </div>
                            <ul className="review">
                              <li className="fill">
                                <i className="icofont-star" />
                              </li>
                              <li className="fill">
                                <i className="icofont-star" />
                              </li>
                              <li className="fill">
                                <i className="icofont-star" />
                              </li>
                              <li className="fill">
                                <i className="icofont-star" />
                              </li>
                              <li className="blank">
                                <i className="icofont-star" />
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      {/* End testimonial Slider Single Item */}
                      {/* Start testimonial Slider Single Item */}
                      <div className="testimonial-slider-single-item swiper-slide">
                        <div className="inner-shape inner-shape-top-right" />
                        <div className="content">
                          <span className="icon">“</span>
                          <p className="text">
                            Lorem Ipsum simpy dummy text of the printing and
                            types industry has been the industr standard dummy.
                          </p>
                          <div className="info">
                            <div className="author">
                              <h4 className="name">Raleigh Friend</h4>
                              <span className="designation">CEO, Seoly</span>
                            </div>
                            <ul className="review">
                              <li className="fill">
                                <i className="icofont-star" />
                              </li>
                              <li className="fill">
                                <i className="icofont-star" />
                              </li>
                              <li className="fill">
                                <i className="icofont-star" />
                              </li>
                              <li className="fill">
                                <i className="icofont-star" />
                              </li>
                              <li className="blank">
                                <i className="icofont-star" />
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      {/* End testimonial Slider Single Item */}
                      {/* Start testimonial Slider Single Item */}
                      <div className="testimonial-slider-single-item swiper-slide">
                        <div className="inner-shape inner-shape-top-right" />
                        <div className="content">
                          <span className="icon">“</span>
                          <p className="text">
                            Lorem Ipsum simpy dummy text of the printing and
                            types industry has been the industr standard dummy.
                          </p>
                          <div className="info">
                            <div className="author">
                              <h4 className="name">Raleigh Friend</h4>
                              <span className="designation">CEO, Seoly</span>
                            </div>
                            <ul className="review">
                              <li className="fill">
                                <i className="icofont-star" />
                              </li>
                              <li className="fill">
                                <i className="icofont-star" />
                              </li>
                              <li className="fill">
                                <i className="icofont-star" />
                              </li>
                              <li className="fill">
                                <i className="icofont-star" />
                              </li>
                              <li className="blank">
                                <i className="icofont-star" />
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      {/* End testimonial Slider Single Item */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="default-nav-style mt-5 mt-xl-0">
          {/* If we need navigation buttons */}
          <div className="slider-button button-prev">
            <i className="icofont-double-left" />
          </div>
          <div className="slider-button button-next">
            <i className="icofont-double-right" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TestimonialDisplay;
